<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
    </div>
    <div class="filtro-valorem d-flex align-items-center mb-4">
      <input-date
        class="col-2 input-label"
        ref="dataInicio"
        :label="$t('PLACEHOLDER.DATA_INICIO')"
        v-model="filtro.dataInicio"
        @blur="filtrar"
      />
      <input-date
        class="col-2 input-label"
        ref="dataFim"
        :label="$t('PLACEHOLDER.DATA_FIM')"
        v-model="filtro.dataFim"
        @blur="filtrar"
      />
      <input-autocomplete
        :label="$t('PLACEHOLDER.ESTABELECIMENTO')"
        v-model="filtro.estabelecimentoId"
        class="flex-fill col-3 autocomplete-relatorio"
        :options="opcoes.estabelecimentos"
        @selected="filtrar"
      />
      <b-form-group :label="$t('PLACEHOLDER.NAORAV')" class="col-2 d-none">
        <input-checkbox
          ref="naoRav"
          v-model="filtro.naoRav"
          @onChange="filtrar"
        />
      </b-form-group>
    </div>

    <tabela
      v-model="tabela.dados"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      ref="tabela"
      sem-selecionar-todos
      class="tabela-relatorios"
      semPaginacao
    >
      <template #cell(estabelecimento)="dados">
        <span>
          {{
            `${dados.item.estabelecimento.nomeFantasia} (${dados.item.estabelecimento.cpfCnpj})`
          }}
        </span>
      </template>
      <template #cell(conta)="dados">
        <span>
          {{
            dados.item.contaBancaria.digitoAgencia
              ? `Banco: ${dados.item.contaBancaria.banco} | Ag.:${dados.item.contaBancaria.agencia}-${dados.item.contaBancaria.digitoAgencia} | Cc.:${dados.item.contaBancaria.conta}-${dados.item.contaBancaria.digitoConta} | Convênio ${dados.item.contaBancaria.convenio}`
              : `Banco: ${dados.item.contaBancaria.banco} | Ag.:${dados.item.contaBancaria.agencia} | Cc.:${dados.item.contaBancaria.conta}-${dados.item.contaBancaria.digitoConta} | Convênio ${dados.item.contaBancaria.convenio}`
          }}
        </span>
      </template>
    </tabela>
    <hr />
    <div class="d-flex align-items-center justify-content-end h5">
      <span class="mr-2 font-weight-light"
        >{{ $t(`RELATORIOS.TOTAIS.VALORES`) }}:</span
      >
      <span class="text-info mr-3">{{ this.valorTotal }}</span>
      <span class="mr-2 font-weight-light"
        >{{ $t(`RELATORIOS.TOTAIS.VALORES_ORIGINAIS`) }}:</span
      >
      <span class="text-success mr-3">{{ this.valorTotalOriginal }}</span>
      <span class="mr-2 font-weight-light"
        >{{ $t(`RELATORIOS.TOTAIS.DESAGIO`) }}:</span
      >
      <span class="text-secondary">{{ this.valorTotalDiferenca }}</span>
    </div>
  </b-card>
</template>

<script>
import CheckoutService from "@/common/services/checkout/checkout.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { AlertasMixin } from "@/common/mixins/AlertasMixin";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  InputDate,
  InputAutocomplete,
  InputCheckbox,
} from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import Tabela from "@/components/tabelas/Tabela.vue";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";

export default {
  mixins: [AlertasMixin],
  name: "Index",
  components: {
    InputDate,
    InputCheckbox,
    Tabela,
    InputAutocomplete,
  },
  data() {
    return {
      filtro: {
        dataInicio: null,
        dataFim: null,
        estabelecimentoId: null,
        naoRav: null,
      },
      tabela: {
        dados: [],
        colunas: [
          {
            key: "estabelecimento",
            label: this.$t("RELATORIOS.NOME_ESTABELECIMENTO"),
          },
          {
            key: "conta",
            label: this.$t("RELATORIOS.CONTA"),
          },
          {
            key: "valor",
            label: this.$t("RELATORIOS.VALOR"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "valorOriginal",
            label: this.$t("RELATORIOS.VALOR_ORIGINAL"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "dataPagamento",
            label: this.$t("RELATORIOS.DATA_PAGAMENTO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            key: "quantidade",
            label: this.$t("RELATORIOS.QUANTIDADE"),
          },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 100,
      },
      opcoes: {
        estabelecimentos: [],
      },
      totalValor: null,
      totalValorOriginal: null,
      totalDiferenca: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.RELATORIOS") },
    ]);
    this.buscarEstabelecimentos();
  },
  methods: {
    filtrar: function () {
      this.listar();
    },
    listar: function () {
      this.$store.dispatch(START_LOADING);
      CheckoutService.relatorios(this.filtro)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.totalValor = res.data.data.totalValor;
          this.totalValorOriginal = res.data.data.totalValorOriginal;
          this.totalDiferenca = res.data.data.totalDiferenca;
          this.tabela.quantidadeItens = res.data.data.registros;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarEstabelecimentos: function () {
      this.opcoes.estabelecimentos = [
        { value: null, text: "Todos estabelecimentos" },
      ];
      EstabelecimentoService.buscarEstabelecimentosDropdown().then((res) => {
        this.opcoes.estabelecimentos.push(
          ...res.data.data.estabelecimentos.map((el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          })
        );
      });
    },
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("Estabelecimento");
    },
    valorTotal() {
      return helpers.formatarValor(this.totalValor);
    },
    valorTotalOriginal() {
      return helpers.formatarValor(this.totalValorOriginal);
    },
    valorTotalDiferenca() {
      return helpers.formatarValor(this.totalDiferenca);
    },
  },
};
</script>

<style lang="scss">
.tabela-relatorios td {
  font-size: 14px;
  padding: 5px !important;
  white-space: nowrap;
}

.tabela-relatorios th {
  white-space: nowrap;
  font-size: 14px;
}

.autocomplete-relatorio .dropdown-content {
  margin-top: 0px !important;
}
</style>
